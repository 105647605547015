<template>
	<div>
		<img src="../../../assets/imges/index/24.png" class="headerimg" />
		<div class="PositionDetails">
			<div class="header">
				<span class="title1">加入我们</span><span class="arrows">></span>
				<span class="title1" style="cursor: pointer;" @click="toPosition">员工招聘</span><span class="arrows">></span>
				<span class="title2" style="color: #2F7FFC;">职位详情</span>
			</div>
			<div class="Positioninfo">
				<div class="Positioninfotitle">
					{{info.name}}<span v-if="info.isNegotiable">面议</span>
						<span v-else>{{info.salary}}-{{info.salaryEnd}}K
							<div class="circle" />{{info.salaryMonth}}薪</span>
				</div>
				<div class="synopsis">
					<span  class="synopsissp">{{info.recordSchoolingName}}</span>
					<span  class="synopsissp">{{info.workingPlace}}</span>
					<span  class="synopsissp">{{info.jobCategoryName}}</span>
					<span  >{{info.recruitNumber}}人</span>
				</div>
				<div class="particulars">
					<div class="Positioninfotitle">
						职位描述
					</div>
					<div class="info">
						<div v-html="info.jobDescription.replace(/\n/g,'<br/>')"></div>
					</div>
				</div>
				<div class="claim">
					<div class="Positioninfotitle">
						职位要求
					</div>
					<div class="info">
						<div v-html="info.jobRequirements.replace(/\n/g,'<br/>')"></div>
					</div>
				</div>
				<div class="email">简历邮箱：<span>{{info.resumeEmail}}</span></div>
				<div class="phone">联系电话：<span>{{info.mobile}}</span></div>
				<div class="time">发布时间：{{info.releaseTime}}</div>
				<div class="deliver">
					<!-- <el-button type="primary">我要投递</el-button> -->
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {HiringFrontendDetail} from '../../../api/index.js'
	export default {
		data() {
			return {
				info:{},
				list: ['111', '222', '333']
			}
		},
		mounted() {
			HiringFrontendDetail({id:this.$route.query.id}).then(res=>{
				this.info = res.data.rows[0]
			})
		},
		methods: {
			toPosition(){
				this.$router.push('Position')
			}
		}
	}
</script>

<style scoped lang="less">
	.PositionDetails {
		width: 80%;
		margin: 0 auto;

		.header {
			padding: 59px 0;
			font-size: 28px;
			border-bottom: 1px solid #979797;

			.title1 {
				font-weight: bold;
				line-height: 42px;
			}

			.arrows {
				color: #BEBEBE;
				padding: 0 25px;
			}

			.title2 {
				font-weight: 500;
				color: #B4B4B4;
				cursor: pointer;
			}

			.title2hover {
				color: #2F7FFC;
				padding-bottom: 15px;
				border-bottom: 6px solid #2F7FFC;

			}

		}

		.Positioninfo {
			width: 80%;
			margin: 0 auto;
			margin-top: 105px;

			.Positioninfotitle {
				font-size: 52px;
				font-weight: 500;
				line-height: 78px;

				span {
					font-size: 28px;
					font-weight: bold;
					color: #FF825C;
					line-height: 42px;
					margin: 0 39px;

					.circle {
						display: inline-block;
						width: 10px;
						height: 10px;
						background: #FF825C;
						vertical-align: middle;
						border-radius: 50%;
						margin: 0 16px;
					}
				}
			}

			.synopsis {
				margin-top: 60px;
				color: #2F7FFC;
				font-size: 24px;
				font-weight: 500;
				
				.synopsissp {
					padding-right: 12px;
					border-right: 3px solid #A2A3A4;
					margin-right: 12px;
				}
			}

			.particulars {
				margin-top: 94px;

				.particularstitle {
					font-size: 32px;
					font-weight: 500;
				}

				.info {
					margin-top: 30px;
					font-size: 18px;
					font-weight: 500;
					color: #A2A3A4;
					line-height: 36px;
				}
			}

			.claim {
				margin-top: 46px;

				.claimtitle {
					font-size: 32px;
					font-weight: 500;
				}

				.info {
					margin-top: 30px;
					font-size: 18px;
					font-weight: 500;
					color: #A2A3A4;
					line-height: 36px;
				}
			}

			.email {
				margin-top: 46px;
				font-size: 18px;
				font-weight: 500;

				span {
					color: #2F7FFC;
				}
			}

			.phone {
				margin-top: 30px;
				font-size: 18px;
				font-weight: 500;

				span {
					color: #2F7FFC;
				}
			}

			.time {
				margin-top: 52px;
				text-align: right;
				font-size: 14px;
				font-weight: 500;
				color: #A2A3A4;
				line-height: 21px;
			}

			.deliver {
				margin-top: 151px;
				text-align: center;
				padding-bottom: 98px;
			}
		}
	}
</style>
